// menuButtonId

const menuButton = document.getElementById("menuButtonId");

const menuAsideContainer = document.querySelector(".menuAsideContainer");
const pageWrapper = document.querySelector(".pageWrapper");

const filterBlur = document.querySelector(".filterBlur");

const innerStroke1 = document.querySelector(".innerStroke1");
const innerStroke2 = document.querySelector(".innerStroke2");
const innerStroke3 = document.querySelector(".innerStroke3");
const navItemLinkHrefs = document.querySelectorAll(".navItemLinkHref");

let isClosed = true;

// console.log({ pageWrapperBody });

pageWrapper.addEventListener("click", () => {
  isClosed = true;
  menuAsideContainer.classList.remove("menuShow");
  document.documentElement.style.overflow = "scroll";
  pageWrapper.classList.remove("filterBlur");
  innerStroke1.classList.remove("innerStroke1Update");
  innerStroke2.classList.remove("innerStroke2Update");
  innerStroke3.classList.remove("innerStroke3Update");
});

navItemLinkHrefs.forEach((navItem) => {
  navItem.addEventListener("click", () => {
    isClosed = true;
    menuAsideContainer.classList.remove("menuShow");
    document.documentElement.style.overflow = "scroll";
    pageWrapper.classList.remove("filterBlur");
    innerStroke1.classList.remove("innerStroke1Update");
    innerStroke2.classList.remove("innerStroke2Update");
    innerStroke3.classList.remove("innerStroke3Update");
  });
});

menuButton.addEventListener("click", () => {
  if (isClosed) {
    menuAsideContainer.classList.add("menuShow");
    isClosed = false;
    document.documentElement.style.overflow = "hidden";
    pageWrapper.classList.add("filterBlur");

    innerStroke1.classList.add("innerStroke1Update");
    innerStroke2.classList.add("innerStroke2Update");
    innerStroke3.classList.add("innerStroke3Update");
    // pageWrapper..documentElement.style.filter = "blur(20px)";
  } else {
    isClosed = true;
    menuAsideContainer.classList.remove("menuShow");
    document.documentElement.style.overflow = "scroll";
    pageWrapper.classList.remove("filterBlur");
    innerStroke1.classList.remove("innerStroke1Update");
    innerStroke2.classList.remove("innerStroke2Update");
    innerStroke3.classList.remove("innerStroke3Update");

    // document.documentElement.style.filter = "";
  }
});
